a.clickable-grid-cell,
a.clickable-grid-cell:hover {
  text-decoration-color: var(--kt-text-secondary);
  text-decoration-line: underline;
  /* text-decoration-thickness: .125em; */
  text-underline-offset: 4px;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
}

.k-animation-container-relative {
  width: 100%;
}
.clickable-grid-cell:hover {
  text-decoration-color: var(--kt-text-secondary);
  text-decoration-line: underline;
  /* text-decoration-thickness: .125em; */
  text-underline-offset: 4px;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
}

.clickable-grid-cell:hover {
  text-decoration-style: none !important;
  font-weight: bold;
}
.file-data {
  user-select: none; /* Mencegah teks dipilih */
  cursor: pointer;
}

a.clickable-grid-cell:hover {
  text-decoration-style: none !important;
  font-weight: bold;
}

.k-form .k-form-field {
  margin-top: 0.5rem;
}

.k-form-horizontal .k-form-field-wrap {
  max-width: 100%;
  /* padding-right: 10px; */
}

.btn-item {
  background-color: transparent;
  color: #0078d7;
}
.btn-item:hover {
  background-color: #0078d71c !important;
  color: #0078d7;
}

input.k-input:disabled,
input.k-input[disabled],
textarea.k-textarea:disabled,
textarea.k-textarea[disabled] {
  opacity: 1 !important;
  border-color: #eee;
}

.leaflet-container {
  width: 100vw;
  height: 100vw;
}
